// app/providers.js
"use client";

import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { usePathname, useSearchParams } from "next/navigation";
import { useEffect } from "react";
import { PAGEVIEW_EVENT } from "@/lib/constants";
import { useAuth, useOrganization, useUser } from "@clerk/nextjs";

if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || "", {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    capture_pageview: false,
    session_recording: {
      recordCrossOriginIframes: true,
      maskAllInputs: false,
      maskInputOptions: {
        password: true,
      },
    },
  });
}

export default function PHProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { userId, orgId, actor } = useAuth();
  const { user } = useUser();
  const { organization } = useOrganization();
  useEffect(() => {
    if (user && !posthog._isIdentified()) {
      posthog?.identify(user.id, {
        email: user.emailAddresses[0].emailAddress,
        username: user.username,
      });
    }
    if (organization?.id) {
      posthog?.group("organization", organization.id, {
        name: organization?.name,
      });
    }
  }, [posthog, user, organization]);

  useEffect(() => {
    if (pathname) {
      let url = window.origin + pathname;
      if (searchParams?.toString()) {
        url = url + `?${searchParams.toString()}`;
      }
      posthog.capture(PAGEVIEW_EVENT, {
        $current_url: url,
      });
    }
  }, [pathname, searchParams]);

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
