"use client";

import Script from "next/script";
import { useState } from "react";

export default function ApryseProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [loadSecondScript, setLoadSecondScript] = useState(false);

  return (
    <>
      {children}
      <Script
        src="/webviewer-full/core/webviewer-core.min.js"
        onLoad={() => {
          setLoadSecondScript(true);
        }}
      />
      {loadSecondScript && <Script src="/webviewer-full/core/pdf/PDFNet.js" />}
    </>
  );
}
