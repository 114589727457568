"use client";

import { EventRun } from "@/components/trigger/event-run-details";
import EventRunDetailsToast from "@/components/trigger/event-run-details-toast";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";

const EventsContext = createContext<{
  events: EventRun[];
  setEvents: Dispatch<SetStateAction<EventRun[]>>;
}>({
  events: [],
  setEvents: (events: SetStateAction<EventRun[]>) => {},
});

export const EventsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [events, setEvents] = useState<EventRun[]>([]);

  return (
    <EventsContext.Provider
      value={{
        events,
        setEvents,
      }}
    >
      {events.map((event, idx) => (
        <EventRunDetailsToast key={idx} event={event} />
      ))}
      {children}
    </EventsContext.Provider>
  );
};

export function useEventsContext() {
  return useContext(EventsContext);
}
