import { useEventRunStatuses } from "@trigger.dev/react";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { Button } from "../ui/button";

export type EventRun = {
  id: string;
  name: string;
  toastId?: string;
  onSuccess?: (output: any) => void;
};

export default function EventRunDetailsToast({
  event,
  retry,
}: {
  event: EventRun | null;
  retry?: (() => Promise<void>) | null | undefined;
}) {
  if (!event?.id) {
    return null;
  }
  const {
    fetchStatus,
    error: statusError,
    statuses,
    run,
  } = useEventRunStatuses(event.id);
  useEffect(() => {
    toast.loading(event.name, {
      id: event?.toastId,
    });
  }, [statuses]);
  const [retrying, setRetrying] = useState<boolean>(false);
  useEffect(() => {
    if (run?.status === "SUCCESS") {
      toast.success(event.name, {
        id: event?.toastId,
      });
      if (event.onSuccess) {
        event.onSuccess(run.output);
      }
    } else if (
      run?.status === "FAILURE" ||
      run?.status === "ABORTED" ||
      run?.status === "CANCELED" ||
      run?.status === "TIMED_OUT"
    ) {
      toast.error(event.name, {
        ...(retry && {
          duration: 600000,
          cancel: {
            label: "Cancel",
            onClick: () => {
              toast.dismiss(event?.toastId);
            },
          },
          action: {
            label: "Retry",
            onClick: async () => {
              await retry();
            },
          },
        }),
        id: event?.toastId,
      });
    }
  }, [run?.status]);
  if (statusError) {
    toast.error(statusError?.message, {
      action: {
        label: "Retry",
        onClick: async () => {
          if (retry) {
            await retry();
          }
        },
      },
      id: event?.toastId,
    });
  }
  return null;
}
