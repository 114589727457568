"use client";

import { useAuth } from "@clerk/nextjs";
import { createClient, SupabaseClient } from "@supabase/supabase-js";
import { createContext, useContext, useEffect, useState } from "react";

const SupabaseContext = createContext<SupabaseClient | null>(null);

export const SupabaseContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [client, setClient] = useState<SupabaseClient | null>(null);
  const { getToken } = useAuth();
  useEffect(() => {
    const init = async () => {
      const token = await getToken({
        template: process.env.NEXT_PUBLIC_CLERK_JWT_TEMPLATE,
      });
      if (!token) {
        return;
      }
      const supabase = createClient(
        process.env.NEXT_PUBLIC_SUPABASE_URL || "",
        process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY || "",
        {
          auth: {
            autoRefreshToken: true,
          },
          global: { headers: { Authorization: `Bearer ${token}` } },
        },
      );
      setClient(supabase);
    };
    init();
  }, [getToken]);
  return (
    <SupabaseContext.Provider value={client}>
      {children}
    </SupabaseContext.Provider>
  );
};

export function useSupabaseContext() {
  return useContext(SupabaseContext);
}
