"use client";

import { MAX_SEARCH_DEPTH } from "@/components/library/document-permissions/constants";
import Spinner from "@/components/shared/spinner";
import { useAuth, useOrganization, useUser } from "@clerk/nextjs";
import axios from "axios";
import { usePathname } from "next/navigation";
import { createContext, useContext, useEffect, useState } from "react";
import useSWR, { KeyedMutator } from "swr";
import useSWRImmutable from "swr/immutable";
import { usePostHog } from "posthog-js/react";

const fetcher = (url: string) => axios.get(url).then((res) => res.data);

const DocIdContext = createContext<{
  docIds: string[];
  existingWorkspaceSettings: Record<string, any>;
  isWorkspaceSettingsLoading: boolean;
  mutateWorkspaceSettings: KeyedMutator<any>;
  latestPath: string | null;
  industry: any[];
  subscriptions: any[];
  subscriptionsLoading: boolean;
  isBasicPlan: boolean;
  isTeamPlan: boolean;
  isBusinessPlan: boolean;
}>({
  docIds: [],
  existingWorkspaceSettings: {},
  isWorkspaceSettingsLoading: true,
  mutateWorkspaceSettings: async () => {},
  latestPath: null,
  industry: [],
  subscriptions: [],
  subscriptionsLoading: true,
  isBasicPlan: false,
  isTeamPlan: false,
  isBusinessPlan: false,
});

export const DocIdContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { userId, orgId } = useAuth();
  const posthog = usePostHog();
  const { data: docIds, isLoading } = useSWR(
    !userId
      ? null
      : `/api/user-permissions/${userId}/get-documents?depth=${MAX_SEARCH_DEPTH}`,
    fetcher,
  );
  const {
    data: existingWorkspaceSettings,
    mutate: mutateWorkspaceSettings,
    isLoading: isWorkspaceSettingsLoading,
  } = useSWR(!orgId ? null : `/api/workspace-settings`, fetcher);

  const { data: clientIndustry, isLoading: isClientIndustryLoading } = useSWR(
    !orgId ? null : `/api/clients/industry`,
    fetcher,
  );
  const [latestPath, setLatestPath] = useState<string | null>(null);
  const pathname = usePathname();
  useEffect(() => {
    if (!pathname?.includes("/settings")) {
      setLatestPath(pathname);
    }
  }, [pathname]);
  const { organization } = useOrganization();
  const { data: subscriptions, isLoading: subscriptionsLoading } = useSWR(
    orgId ? `/api/create-subscription?noProductName=true&orgId=${orgId}` : null,
    fetcher,
  );
  return (
    <DocIdContext.Provider
      value={{
        docIds: docIds?.docIds || [],
        existingWorkspaceSettings: existingWorkspaceSettings || {
          deal_stages: [
            {
              id: "dealopened",
              label: "Deal Opened",
            },
            {
              id: "qualified",
              label: "Qualified",
            },
            {
              id: "presales",
              label: "Pre-Sales",
            },
            {
              id: "won",
              label: "Won",
            },
            {
              id: "lost",
              label: "Lost",
            },
            {
              id: "onboarding",
              label: "Onboarding",
            },
            {
              id: "support",
              label: "Support",
            },
          ],
        },
        isWorkspaceSettingsLoading: isWorkspaceSettingsLoading,
        mutateWorkspaceSettings: mutateWorkspaceSettings,
        latestPath: latestPath,
        industry: clientIndustry?.industries?.filter((i: any) => i) || [],
        subscriptions: subscriptions || [],
        subscriptionsLoading: subscriptionsLoading,
        isBasicPlan:
          subscriptions?.[0]?.plan?.id ===
          process.env.NEXT_PUBLIC_DEALPAGE_BASICS_PRICE_ID,
        isTeamPlan:
          subscriptions?.[0]?.plan?.id ===
          process.env.NEXT_PUBLIC_DEALPAGE_TEAM_PRICE_ID,
        isBusinessPlan:
          posthog?.isFeatureEnabled("business-plan") ||
          (subscriptions?.[0]?.plan?.id !==
            process.env.NEXT_PUBLIC_DEALPAGE_BASICS_PRICE_ID &&
            subscriptions?.[0]?.plan?.id !==
              process.env.NEXT_PUBLIC_DEALPAGE_TEAM_PRICE_ID &&
            subscriptions?.[0]?.status === "active"),
      }}
    >
      {children}
    </DocIdContext.Provider>
  );
};

export function useDocIdContext() {
  return useContext(DocIdContext);
}
