export const DEAL_NAMESPACE: string = "Deal";
export const DOCUMENT_NAMESPACE: string = "Document";
export const COLLECTION_NAMESPACE: string = "Collection";
export const USER_NAMESPACE: string = "User";
export const FOLDER_NAMESPACE: string = "Folder";

export const OWNER: string = "Owner";
export const EDITOR: string = "Editor";
export const VIEWER: string = "Viewer";
export const REMOVE_ACCESS: string = "Remove Access";

export const KETO_OWNER_RELATION: string = "owners";
export const KETO_EDITOR_RELATION: string = "editors";
export const KETO_VIEWER_RELATION: string = "viewers";
export const KETO_PARENT_RELATION: string = "parents";

export const KETO_VIEW_PERMISSION: string = "view";
export const KETO_EDIT_PERMISSION: string = "edit";
export const KETO_SHARE_PERMISSION: string = "share";
export const KETO_DELETE_PERMISSION: string = "delete";

export const GOOGLE_OWNER_ROLE: string = "owner";
export const GOOGLE_EDITOR_ROLE: string = "writer";
export const GOOGLE_VIEWER_ROLE: string = "reader";
export const GOOGLE_COMMENTER_ROLE: string = "commenter";

export const ONEDRIVE_OWNER_ROLE: string = "owner";
export const ONEDRIVE_EDITOR_ROLE: string = "write";
export const ONEDRIVE_VIEWER_ROLE: string = "read";
export const ONEDRIVE_MEMBER_ROLE: string = "member";

export const MAX_SEARCH_DEPTH = 5;

// The permission we display in the frontend and the relation that keto uses
// are different (e.g. "Owner" vs "owners") so we need to convert between them.
export const PERMISSION_TO_KETO_RELATION: Record<string, string> = {
  [OWNER]: KETO_OWNER_RELATION,
  [EDITOR]: KETO_EDITOR_RELATION,
  [VIEWER]: KETO_VIEWER_RELATION,
};

export const KETO_RELATION_TO_PERMISSION: Record<string, string> = {
  [KETO_OWNER_RELATION]: OWNER,
  [KETO_EDITOR_RELATION]: EDITOR,
  [KETO_VIEWER_RELATION]: VIEWER,
};

// Mapping from Google Drive roles to Keto relations.
export const GOOGLE_ROLE_TO_KETO_RELATION: Record<string, string> = {
  [GOOGLE_OWNER_ROLE]: KETO_OWNER_RELATION,
  [GOOGLE_EDITOR_ROLE]: KETO_EDITOR_RELATION,
  [GOOGLE_VIEWER_ROLE]: KETO_VIEWER_RELATION,
  // Map Google Drive commenter to Keto viewer
  [GOOGLE_COMMENTER_ROLE]: KETO_VIEWER_RELATION,
};

// Mapping from OneDrive roles to Keto relations.
export const ONEDRIVE_ROLE_TO_KETO_RELATION: Record<string, string> = {
  [ONEDRIVE_OWNER_ROLE]: KETO_OWNER_RELATION,
  [ONEDRIVE_EDITOR_ROLE]: KETO_EDITOR_RELATION,
  [ONEDRIVE_VIEWER_ROLE]: KETO_VIEWER_RELATION,
  [ONEDRIVE_MEMBER_ROLE]: KETO_VIEWER_RELATION,
};

export const DELETE: string = "delete";
export const INSERT: string = "insert";

export const ORGANIZATION_SHARING_MODE = "ORGANIZATION";
export const INVITE_ONLY_SHARING_MODE = "INVITE_ONLY";
